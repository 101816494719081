/** @format */

@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  font-family: "Nunito Sans", sans-serif;
  padding: 0;
  margin: 0;
}

.rich-text-editor .ql-toolbar {
  background: transparent;
  color: rgb(230, 219, 219);
  min-height: 48px;
  border-radius: 8px 8px 0 0;
  border: 1px solid black;
}

.rich-text-editor .ql-container {
  border-radius: 0 0 8px 8px;
  border: 1px solid black;
  border-top: none;
  background: white;
}

.rich-text-editor .ql-editor {
  min-height: 300px;
  font-size: 16px;
}

.editor-label {
  display: block;
  margin-bottom: 8px;
  color: white;
  font-weight: 500;
}

.error-message {
  color: #ff4d4f;
  margin-top: 8px;
  font-size: 14px;
}

.editor-error .ql-toolbar {
  border: 1px solid #ff4d4f !important;
}
